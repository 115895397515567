import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getNewReports} from "../../api/vpfr";

const parseItems = (array, items) => {
    for (const itemKey in items) {
        const index = array.findIndex(c => c.key === itemKey);
        if (index === -1) {
            array.push({
                key: itemKey,
                name: items[itemKey].name,
                price: items[itemKey].price,
                quantity: items[itemKey].quantity,
                tax: items[itemKey].tax,
                totalAmount: items[itemKey].totalAmount,
                uid: items[itemKey].uid
            });
        } else {
            array[index].quantity += items[itemKey].quantity;
            array[index].totalAmount += items[itemKey].totalAmount;
        }
    }
};

const parseTax = (array, tax, isRefund) => {
    for (const key in tax) {
        const index = array.findIndex(c => c.key === key);
        if (index === -1) {
            array.push({
                key: key,
                amountSale: isRefund ? 0 : tax[key],
                amountRefund: isRefund ? tax[key] : 0
            });
        } else {
            if (isRefund) {
                array[index].amountRefund += tax[key];
            } else {
                array[index].amountSale += tax[key];
            }
        }
    }
};

const parsePayment = (array, payment, isRefund) => {
    for (const key in payment) {
        const index = array.findIndex(c => c.key === key);
        if (index === -1) {
            array.push({
                key: key,
                amountSale: isRefund ? 0 : payment[key],
                amountRefund: isRefund ? payment[key] : 0
            });
        } else {
            if (isRefund) {
                array[index].amountRefund += payment[key];
            } else {
                array[index].amountSale += payment[key];
            }
        }
    }
};
const parseCasheirs = (array, cashier) => {
    for (const name in cashier) {
        const itemsSale = [], itemsRefund = [], payment = [], tax = [];
        let invoiceNumberSale = 0;
        let invoiceNumberRefund = 0;
        let totalSale = 0;
        let totalRefund = 0;
        const index = array.findIndex(c => c.username === name);
        for (const key in cashier[name]) {
            if (key === "itemsSale") {
                parseItems(itemsSale, cashier[name][key]);
            } else if (key === "itemsRefund") {
                parseItems(itemsRefund, cashier[name][key]);
            } else {
                if (Number(key) === 0) {
                    if (cashier[name][key]) {
                        invoiceNumberSale += cashier[name][key].count;
                        totalSale += cashier[name][key].amount;
                        parseTax(tax, cashier[name][key].tax, false);
                        parsePayment(payment, cashier[name][key].payment, false);
                    }
                } else {
                    if (Number(key) === 1) {
                        if (cashier[name][key]) {
                            invoiceNumberRefund += cashier[name][key].count;
                            totalRefund += cashier[name][key].amount;
                            parseTax(tax, cashier[name][key].tax, true);
                            parsePayment(payment, cashier[name][key].payment, true);
                        }
                    }
                }
            }
        }
        if (index === -1) {
            array.push({
                username: name,
                itemsSale,
                itemsRefund,
                payment,
                tax,
                invoiceNumberSale,
                totalSale,
                invoiceNumberRefund,
                totalRefund
            });
        } else {
            //TODO dodaj iteme, placanja, i ostalo
            array[index].totalRefund += totalRefund;
            array[index].totalSale += totalSale;
            array[index].invoiceNumberSale += invoiceNumberSale;
            array[index].invoiceNumberRefund += invoiceNumberRefund;
        }
    }

};

export const fetchReportForDate = createAsyncThunk("", async ({dateFrom, dateTo, location, operator}) => {
    //TODO logika( hvataj offline, a ako nema onda idi online)
    // TODO dodati da filtrira i po operateru
    const result = (await getNewReports(dateFrom, dateTo)).data;
    console.log('result', result)
    let forProcessing = result.derived || {};
    if (location) {
        forProcessing = {[location]: result?.derived?.[location]};
        if (!forProcessing) {
            forProcessing = {};
        }
    }
    const
        itemsSale = [],
        itemsRefund = [],
        payment = [],
        cashier = [],
        tax = [];
    const summary = {
        basicRefund: 0,
        basicSale: 0,
        invoiceCountRefund: 0,
        invoiceCountSale: 0,
        taxRefund: 0,
        taxSale: 0,
        total: 0,
        totalRefund: 0,
        totalSale: 0
    };
    for (const JID in forProcessing) {
        if (forProcessing[JID]) {
            console.log(forProcessing[JID])
            parseCasheirs(cashier, forProcessing[JID].cashier);
            parseItems(itemsSale, forProcessing[JID].itemsSale);
            parseItems(itemsRefund, forProcessing[JID].itemsRefund);
            for (const paymentKey in forProcessing[JID].payment) {
                parsePayment(payment, forProcessing[JID].payment[paymentKey], Number(paymentKey) === 1);
            }
            for (const taxKey in forProcessing[JID].tax) {
                parseTax(tax, forProcessing[JID].tax[taxKey], Number(taxKey) === 1);
            }
            summary.basicRefund += forProcessing[JID].summary.basicRefund;
            summary.basicSale += forProcessing[JID].summary.basicSale;
            summary.invoiceCountRefund += forProcessing[JID].summary.invoiceCountRefund;
            summary.invoiceCountSale += forProcessing[JID].summary.invoiceCountSale;
            summary.taxRefund += forProcessing[JID].summary.taxRefund;
            summary.taxSale += forProcessing[JID].summary.taxSale;
            summary.total += forProcessing[JID].summary.total;
            summary.totalRefund += forProcessing[JID].summary.totalRefund;
            summary.totalSale += forProcessing[JID].summary.totalSale;
        }
    }

    return {
        data: result.data,
        derived: result.derived,
        report: {
            itemsSale,
            itemsRefund,
            payment,
            cashier,
            tax,
            summary
        }
    }
});

const initialState = {
    derived: {},
    report: {
        itemsSale: [],
        itemsRefund: [],
        payment: [],
        cashier: [],
        tax: [],
        summary: {
            basicRefund: 0,
            basicSale: 0,
            invoiceCountRefund: 0,
            invoiceCountSale: 0,
            taxRefund: 0,
            taxSale: 0,
            total: 0,
            totalRefund: 0,
            totalSale: 0
        }
    },
    loading: false
};

export const newReportsSlice = createSlice({
    name: 'newReports',
    initialState,
    reducers: {
        resetReport: state => {
            state.report = initialState.report;
        }
    },
    extraReducers: {
        [fetchReportForDate.pending]: (state) => {
            state.loading = true;
        },
        [fetchReportForDate.fulfilled]: (state, {payload}) => {
            const {derived, report} = payload
            state.report = report;
            state.derived = derived;
            state.loading = false;
        },
        [fetchReportForDate.rejected]: (state) => {
            state.loading = false;
        }
    }
});

export const {resetReport} = newReportsSlice.actions;

export const newReportsReducer = newReportsSlice.reducer
