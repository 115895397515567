import {Box, Card, Divider, Stack, Typography} from "@mui/material";
import moment from "moment/moment";
import Image from "../../components/image";
import {getSessionUser} from "../../helper/session";
import Iconify from "../../components/Iconify";
import useResponsive from "../../hooks/useResponsive";
import {findFlag} from "../Home/Results";
import {isTimeTenMinutesBefore} from "../Home/helper";
import React, {useEffect, useRef, useState} from "react";
import {fCurrency} from "../../utils/formatNumber";

const text_speed = 100;

export default function DepartureCard({trip, index, issuingItemsPrice}) {
    const isDesktop = useResponsive("up", "lg");
    const flagArr = findFlag(trip.toCountryName);
    const flagDep = findFlag(trip.fromCountryName);
    const scrollingTextRef = useRef(null);
    const [duration, setDuration] = useState(0);

    const marqueeContainerStyle = {
        overflow: 'hidden',
        width: '100%',
        position: 'relative',
    };

    const marqueeKeyframes = `
    @keyframes scrollText {
        0% { transform: translateX(0); }
        100% { transform: translateX(-100%); }
    }
`;

    const scrollingText = addIssuingItemsOnText(trip?.priceTicker || '', issuingItemsPrice);

    useEffect(() => {
        const textWidth = scrollingTextRef?.current?.offsetWidth;
        const containerWidth = scrollingTextRef?.current?.parentElement?.offsetWidth;
        const totalWidth = textWidth + containerWidth;

        setDuration(totalWidth / text_speed);
    }, [scrollingText, text_speed]);

    const marqueeStyle = {
        display: 'inline-block',
        paddingLeft: '100%',
        whiteSpace: 'nowrap',
        animation: `scrollText ${duration}s linear infinite`,
    };

    return (
        <Box>
            <style>{marqueeKeyframes}</style>
            <Stack
                direction="row"
                sx={{
                    userSelect: "none",
                    background: "transparent",
                    borderRadius: 2,
                    mt: 2
                }}>
                <Card sx={{
                    p: 2,
                    width: '75%',
                    backgroundColor: isTimeTenMinutesBefore(trip.fromStationDepartureDt)
                        ? '#ffe3e3'
                        : (index % 2 ? 'transparent' : '#cee4fd')
                }}>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Stack spacing={1}>
                        </Stack>
                        <Stack sx={{alignItems: 'center', justifyContent: 'center'}}>
                            {moment().isBefore(trip.fromStationDepartureDt, "date") ?
                                <Typography>
                                    {moment(trip.fromStationDepartureDt).format("DD.MM.YYYY")}
                                </Typography>
                                : null}
                            <Typography
                                variant="h2"
                                sx={{
                                    fontWeight: 700,
                                    color: "#6DABE4"
                                }}>
                                {moment(trip?.fromStationDepartureDt).format("HH:mm")}
                            </Typography>
                            <Typography sx={{fontSize: 16, letterSpacing: 1.2}}
                                        variant="h4">
                                {trip.fromStationName}
                            </Typography>
                            <Typography sx={{letterSpacing: 1.2}}
                                        variant="caption">
                                ({trip.fromCountryName})
                            </Typography>
                        </Stack>
                        <Stack direction="column" alignItems="center">
                            <Typography variant="subtitle1" sx={{
                                fontSize: 18,
                                color: "text.disabled"
                            }}>
                                {trip.fromStationName?.toUpperCase()?.slice(0, 3)}
                            </Typography>
                            <Divider orientation="vertical" sx={{width: '100%', borderRadius: 5, borderWidth: 3}}/>

                            {flagDep !== "" ?
                                <Image src={flagDep}
                                       disabledEffect
                                       alt="pin"
                                       sx={{height: 30}}
                                />
                                :
                                <Typography variant="subtitle2"
                                            sx={{color: "text.disabled"}}>{findFlag(trip.fromCountryName)}</Typography>
                            }
                        </Stack>
                        <Stack sx={{display: 'flex', flex: 1}}>
                            {trip?.issuedFor?.name?.length > 0 ?
                                <Stack spacing={1}>
                                    <Typography variant="h6"
                                                sx={{letterSpacing: 1.2, textAlign: 'center', color: 'success.main'}}>
                                        {trip?.issuedFor?.name?.toUpperCase()}
                                    </Typography>
                                </Stack>
                                :
                                <Typography variant="h6"
                                            sx={{letterSpacing: 1.2, textAlign: 'center', color: 'success.main'}}>
                                    {getSessionUser()?.name?.toUpperCase()}
                                </Typography>
                            }
                            <Stack direction="row" spacing={1.2} justifyContent="center" alignItems="center">
                                <Typography
                                    sx={{fontStyle: 'italic', textAlign: 'center', color: 'success.info'}}>
                                    PREVOZNIK:
                                </Typography>
                                <Typography variant="h5"
                                            sx={{fontStyle: 'italic', textAlign: 'center', color: 'success.info'}}>
                                    {trip?.operatorName?.toUpperCase()}
                                </Typography>
                            </Stack>
                            <Divider textAlign="center">
                                <Typography variant="subtitle1"
                                            sx={{color: "text.disabled"}}>{trip?.tripTime}</Typography>
                            </Divider>

                            <Stack direction="row" spacing={1.2} justifyContent="center" alignItems="center">
                                <Typography
                                    sx={{fontStyle: 'italic', textAlign: 'center', color: 'success.info'}}>
                                    LINIJA:
                                </Typography>
                                <Typography variant="h5"
                                            sx={{fontStyle: 'italic', textAlign: 'center', color: 'success.info'}}>
                                    {trip?.lineName?.toUpperCase()}
                                </Typography>
                            </Stack>
                        </Stack>

                        <Stack direction="column" alignItems="center">
                            <Typography variant="subtitle1"
                                        sx={{color: "text.disabled"}}>{trip.toStationName.slice(0, 3).toUpperCase()}</Typography>
                            <Divider orientation="vertical" sx={{width: '100%', borderRadius: 5, borderWidth: 3}}/>
                            {flagArr !== "" ?
                                <Image src={flagArr}
                                       disabledEffect
                                       alt="pin"
                                       sx={{height: 30}}
                                />
                                :
                                <Typography variant="subtitle2"
                                            sx={{color: "text.disabled"}}>{findFlag(trip.fromStationCountry)}</Typography>
                            }
                        </Stack>
                        <Stack sx={{alignItems: 'center', justifyContent: 'center'}}>
                            {moment().isBefore(trip.toStationArrivalDt, "date") ?
                                <Typography>
                                    {moment(trip.toStationArrivalDt).format("DD.MM.YYYY")}
                                </Typography>
                                : null}
                            <Typography
                                variant="h2"
                                sx={{
                                    fontWeight: 700,
                                    color: "#6DABE4"
                                }}>
                                {moment(trip.toStationArrivalDt).format("HH:mm")}
                            </Typography>
                            <Typography
                                sx={{fontSize: 16, letterSpacing: 1.2}}
                                variant="h4">
                                {trip.toStationName}
                            </Typography>
                            <Typography
                                sx={{letterSpacing: 1.2}}
                                variant="caption">
                                ({trip.toCountryName})
                            </Typography>
                        </Stack>
                    </Stack>
                    {trip?.comment &&
                        <Stack direction="row" spacing={1} justifyContent="center" alignItems={"center"} width={1}>
                            <Typography variant="subtitle2" color="warning.text">
                                {trip?.comment?.slice(0, 40)}
                            </Typography>
                            <Iconify icon={'ph:info-duotone'} width={16}/>
                        </Stack>}
                    <Box sx={marqueeContainerStyle}>
                        <Typography variant="h5"
                                    sx={{...marqueeStyle, letterSpacing: 1.2, fontStyle: "italic", color: 'inherit'}}
                                    ref={scrollingTextRef}>
                            {scrollingText}
                        </Typography>
                    </Box>
                </Card>
                <Divider
                    orientation={isDesktop ? 'vertical' : 'horizontal'}
                    flexItem
                    sx={{borderRightWidth: 4, borderStyle: 'dashed'}}
                />
                <Card sx={{
                    p: 2, width: '25%',
                    backgroundColor: isTimeTenMinutesBefore(trip.fromStationDepartureDt)
                        ? '#ffe3e3'
                        : (index % 2 ? 'transparent' : '#cee4fd')
                }}>
                    <Stack justifyContent="center" direction="column" spacing={1}>
                        <Stack justifyContent="space-between" alignItems="center" direction="row" spacing={1}>
                            <Typography variant="h5">
                                U jednom smeru:
                            </Typography>
                            <Typography variant="h5" sx={{textAlign: 'right', color: 'success.main', mt: 1}}>
                                {trip.lastPriceOneWay > 0 ? fCurrency(`${trip.lastPriceOneWay + issuingItemsPrice}`) : "/"} RSD
                            </Typography>
                        </Stack>
                        <Stack justifyContent="space-between" alignItems="center" direction="row" spacing={1}>
                            <Typography variant="h5">
                                Povratna:
                            </Typography>
                            <Typography variant="h5" sx={{textAlign: 'right', color: 'success.main', mt: 1}}>
                                {trip.lastPriceReturnTicket > 0 ? fCurrency(`${trip.lastPriceReturnTicket + issuingItemsPrice}`) : "/"} RSD
                            </Typography>
                        </Stack>
                        <Stack justifyContent="space-between" alignItems="center" direction="row" spacing={1}>
                            <Typography variant="h5">
                                Peron:
                            </Typography>
                            <Typography variant="h3" sx={{textAlign: 'right', color: 'primary.main', mt: 1}}>
                                {trip?.platformNumber || "/"}
                            </Typography>
                        </Stack>
                    </Stack>
                </Card>
            </Stack>
        </Box>
    );
}

function addIssuingItemsOnText(text, issuingItemsPrice) {
    return text.replace(/(\d+)\.?\d*\s*RSD/g, (match, price) => {
        if (parseFloat(price) > 0) {
            let newPrice = parseFloat(price) + issuingItemsPrice;
            return `${fCurrency(`${newPrice}`)} RSD`;
        }
        return `/ RSD`;
    });
}


